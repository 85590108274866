import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/Clubs/Cyber/2023/junior/1.jpg';
import p2 from 'assests/Photos/Clubs/Cyber/2023/junior/2.jpg';
import p3 from 'assests/Photos/Clubs/Cyber/2023/junior/3.jpg';
import p4 from 'assests/Photos/Clubs/Cyber/2023/junior/4.jpg';
import p5 from 'assests/Photos/Clubs/Cyber/2023/junior/5.jpg';
import CyberClub from 'views/CyberClub';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import Archive2022 from 'views/CyberClub/components/Archive2022';
import { Sidebar2023 } from './component';


const LibraryManagement2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1.2,
      cols: 3,
    },
    {
      src: p2,
      source:p2,
      rows: 1.2,
      cols: 3,
    },
    {
      src: p3,
      source: p3,
      rows: 1.2,
      cols: 3,
    },
    {
      src: p4,
      source: p4,
      rows: 1.2,
      cols: 3,
    },
    {
        src: p5,
        source: p5,
        rows: 1.2,
        cols: 3,
      },
  


    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Designing a Website on Library Management System
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
     Class: 6 to 8  Date: 21 SEPTEMBER 2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The Cyber Association organized the finals of the Inter-house competition centered around <strong>
“Designing a Website for Library Management System” on September 21, 2023.</strong>
        <br/>
        This competition was held for the students of Classes 6 to 8. The primary goal was to inspire the young minds to dive deeper into the technologies and nurture their problem-solving abilities.
The finalists, representing each of the four houses, utilized HTML to craft their respective Library Management System websites. They thoroughly enjoyed the collaborative process, which allowed them to generate innovative solutions and to explore their interest for coding. The judging criteria included the following rubrics: Creativity and Innovation, Content quality, Proficiency in HTML Tags and the overall design of their websites.

        <br></br>
        
        This activity encouraged the students to recognize their skills and interests in Web-designing and also to consider the basic requirements for a Library Management System.

        </Typography>
      </Box>
      <Typography
          variant={'h5'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
      "Books and bytes, side by side, enrich the mind."
<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>

             <Box marginBottom={4}>
               <Sidebar2023 />
             </Box> 

             <Box marginBottom={4}>
               <Archive2022 />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default LibraryManagement2023;